/* @import url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .owl-carousel{
width: 644px !important; 
} */

::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  /* background-color: red; */
  scrollbar-color: transparent transparent;
}

/* @-moz-document url-prefix() { /* Disable scrollbar Firefox */
/* #notifications{
    scrollbar-width: none;
  }
} */

.ant-table-thead .ant-table-cell {
  background-color: #ead2ab;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fff8ec;
}

.react-datepicker,
.react-datepicker__month-container {
  width: 100%;
}

/* .ant-table-expanded-row > .ant-table-cell {
  padding: 0;
} */
