@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Proxima+Nova);
body {
  margin: 0;
  font-family: 'Proxima Nova';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTab-root.Mui-selected{
  color: '#FF5674'
}

/* Dropdown Content (Hidden by Default) */

.dropdown-download,.dropdown-download1 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

#myDropdown2 a:active,#myDropdown2 a:hover,#myDropdown3 a:active,#myDropdown3 a:hover,#myDropdown4 a:active,#myDropdown4 a:hover{
  background-color: rgb(255, 240, 215) !important;
}

/* Links inside the dropdown */

.dropdown-download a,.dropdown-download1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}


/* Change color of dropdown links on hover */

.dropdown-download a:hover,.dropdown-download1 a:hover {
  background-color: #ddd
}


/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */

.show,.show1,.show2 {
  display: block !important;
}
/* @import url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .owl-carousel{
width: 644px !important; 
} */

::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  /* background-color: red; */
  scrollbar-color: transparent transparent;
}

/* @-moz-document url-prefix() { /* Disable scrollbar Firefox */
/* #notifications{
    scrollbar-width: none;
  }
} */

.ant-table-thead .ant-table-cell {
  background-color: #ead2ab;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fff8ec;
}

.react-datepicker,
.react-datepicker__month-container {
  width: 100%;
}

/* .ant-table-expanded-row > .ant-table-cell {
  padding: 0;
} */

.spanStyle {
    font-weight: 700;
    letter-spacing: 1px;
}
.tab-component_continer {
    height: -webkit-max-content;
    height: max-content;
    width: 100%;
    min-width: 80%;
    max-width: 100%;
    min-height: auto;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}
.tab-component_continer .tab-header_section {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}
.tab-component_continer .tab-header_section .tab-heading-text {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #0c1b0b;
    display: flex;
    align-items: center;
    margin: 0 10px !important;
}
.tab-component_continer .tab-header_section .tab-heading-button {
    margin-left: auto;
    padding: 0 15px !important;
}
.tab-component_continer .tab-header_section .tab-heading-button button {
    height: 32px;
    width: 140px;
    border-radius: 7px;
    background: #24c394;
    color: white;
    border: none;
    outline: none;
    font-size: 14px;
}
.tab-component_continer .tab-header_section .tab-heading-button button:focus {
    border: none;
    outline: unset !important;
}
.tab-component_continer .tab-body {
    margin-top: 16px;
    text-align: left;
}
.tab-component_continer .tab-body .MuiTab-root {
    min-width: -webkit-max-content;
    min-width: max-content;
    padding-left: 0;
    padding: 0 15px !important;
    font-size: 12px;
}
.tab-component_continer .tab-body .MuiTab-root .tabs button:focus {
    outline: unset !important;
}
.tab-component_continer .tab-body .MuiTab-textColorSecondary {
    color: #8a9ebc;
}
.tab-component_continer .tab-body .MuiTab-textColorSecondary.Mui-selected {
    color: #0052cc;
}
.tab-component_continer .tab-body .PrivateTabIndicator-colorSecondary-3 {
    background: black 10% !important;
    margin-bottom: -0.5px;
}
.tab-component_continer .tab-body .MuiTabPanel-root {
    padding-left: 0;
    padding: 15px !important;
}
.tab-component_continer .tab-body .tab-cust-tab {
    border-bottom: 1px solid #e8e8e8;
}
button:focus {
    outline: unset !important;
    border: none !important;
}
.bubble {
    background-color: #fca016;
    border-radius: 20px;
    font-size: 10px;
    width: 15px;
    min-width: 15px;
    padding: 0 5px;
    margin-left: 3px;
    color: #fff;
}
.mainStep2 {
    position: absolute;
    padding: 16px;
    background: linear-gradient(180deg, #01488b 0%, #0159a9 100%);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    right: 50px;
    width: 350px;
    color: #fff;
    top: 53px;
    z-index: 1300;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}
.step2arrowTop {
    position: relative;
}
.step2arrowTop {
    position: relative;
    content: " ";
    position: absolute;
    right: 10px;
    top: -20px;
    border-top: none;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: 20px solid #01488b;
}
.mainGuidedTourStep10 {
    position: absolute;
    background: linear-gradient(180deg, #01488b 0%, #0159a9 100%);
    box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px;
    top: -70px;
    left: 100px;
    z-index: 1300;
    color: #fff;
    width: 360px;
}
.mainGuidedTourStep10Content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.mainGuidedTourStep10 > p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}
.mainGuidedTourStep10Arrow {
    position: relative;
}
.mainGuidedTourStep10Arrow:after {
    content: " ";
    position: absolute;
    left: 30px;
    bottom: -17px;
    border-top: 7px solid #0159a9;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: none;
}
.step10Btn {
    background: #25b68a;
    border-radius: 10px;
    border: 0;
    color: #fff;
    width: 80px;
    cursor: pointer;
}
.mainGuidedTourStep9 {
    position: absolute;
    background: linear-gradient(180deg, #01488b 0%, #0159a9 100%);
    box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px;
    top: -70px;
    left: 350px;
    z-index: 1300;
    color: #fff;
    width: 450px;
}
.mainGuidedTourStep9Arrow {
    position: relative;
}
.mainGuidedTourStep9Arrow:after {
    content: " ";
    position: absolute;
    left: 30%;
    bottom: -17px;
    border-top: 7px solid #0159a9;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: none;
}
.mainGuidedTourStep11 {
    position: absolute;
    color: #fff;
    background: linear-gradient(180deg, #01488b 0%, #0159a9 100%);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    padding: 10px 10px 10px 30px;
    width: 400px;
    left: 200px;
    top: 80px;
    z-index: 1400;
}
.mainGuidedTourStep11 > p {
    margin: 0;
}

.avatar {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 35px;
    height: 35px;
    background-color: #d2d2d2;
    margin: auto;
    position: relative;
    border: 2px solid white;
}
.avatar:not(:first-child) {
    margin-left: -15px;
}
.avatar img {
    width: 100%;
    display: block;
}
.span-received {
    border: 1px solid #0784f8;
    border-radius: 6px;
    padding: 2px 6px;
}
.span-received:hover {
    background-color: #0784f8;
    color: #fff;
}
.mainGuidedTourStep11 {
    position: absolute;
    color: #fff;
    background: linear-gradient(180deg, #01488b 0%, #0159a9 100%);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    padding: 10px 10px 10px 30px;
    width: 400px;
    right: 50%;
    top: -100px;
    z-index: 1400;
}
.mainGuidedTourStep11 > p {
    margin: 0;
}
.mainGuidedTourStep11Arrow {
    content: " ";
    position: absolute;
    left: 30px;
    bottom: -15px;
    border-top: 15px solid #01488b;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
}

.app-dashboard {
  position: absolute !important;
  width: 100% !important;
  left: 0px !important;
  top: 66px !important;
  bottom: 57px !important;
  overflow: scroll !important;
}

.dashboard-widget {
  display: flex;
  overflow: scroll;
}

.dashboard-tabs {
  margin-left: 17px;
}

.tab-view {
  margin: 8px !important;
}

.app-dashboard-upcoming-events {
  position: absolute !important;
  width: 100% !important;
  left: 0px !important;
}

.share-via {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.ant-design-custom {
  /* display: none; */
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.lead_remark {
  text-overflow: ellipsis;
  height: 20px;
  max-width: 220px;
  overflow: hidden;
  word-break: break-all;
}

