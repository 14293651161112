.avatar {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 35px;
    height: 35px;
    background-color: #d2d2d2;
    margin: auto;
    position: relative;
    border: 2px solid white;
}
.avatar:not(:first-child) {
    margin-left: -15px;
}
.avatar img {
    width: 100%;
    display: block;
}
.span-received {
    border: 1px solid #0784f8;
    border-radius: 6px;
    padding: 2px 6px;
}
.span-received:hover {
    background-color: #0784f8;
    color: #fff;
}
.mainGuidedTourStep11 {
    position: absolute;
    color: #fff;
    background: linear-gradient(180deg, #01488b 0%, #0159a9 100%);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 10px 10px 30px;
    width: 400px;
    right: 50%;
    top: -100px;
    z-index: 1400;
}
.mainGuidedTourStep11 > p {
    margin: 0;
}
.mainGuidedTourStep11Arrow {
    content: " ";
    position: absolute;
    left: 30px;
    bottom: -15px;
    border-top: 15px solid #01488b;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
}
