.app-dashboard {
  position: absolute !important;
  width: 100% !important;
  left: 0px !important;
  top: 66px !important;
  bottom: 57px !important;
  overflow: scroll !important;
}

.dashboard-widget {
  display: flex;
  overflow: scroll;
}

.dashboard-tabs {
  margin-left: 17px;
}

.tab-view {
  margin: 8px !important;
}

.app-dashboard-upcoming-events {
  position: absolute !important;
  width: 100% !important;
  left: 0px !important;
}

.share-via {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.ant-design-custom {
  /* display: none; */
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.lead_remark {
  text-overflow: ellipsis;
  height: 20px;
  max-width: 220px;
  overflow: hidden;
  word-break: break-all;
}
